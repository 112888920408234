import React from 'react';
import Seo from '../../components/Seo';
import styled from 'styled-components';
import ButtonLink, { ButtonColor } from '../../components/ButtonLink';
import JoinTheCloudSection from '../../homeSections/JoinTheCloudSection';
import MarginContainer from '../../components/MarginContainer';
import MariaDb from '../../images/databases/MariaDb.svg';
import MySql from '../../images/databases/MySql.svg';
import PostgreSql from '../../images/databases/PostgreSql.svg';
import PageHeading, {
  ActionButtonsContainer,
} from '../../components/PageHeading';
import Paragraph from '../../components/Paragraph';
import Heading2 from '../../components/pageElements/Heading2';
import PageContentWrapper from '../../components/pageElements/PageContentWrapper';
import SmallLineDots from '../../images/backgrounds/SmallLineDots.svg';
import { device } from '../../utils/breakpoints';
import { graphql, useStaticQuery } from 'gatsby';
import MultilineText from '../../components/MultiLineText';
import useCommonTitles from '../../components/useCommonTitles';

const ProductsList = styled.div`
  padding-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const Product = styled.div`
  background: ${(props) => props.theme.white};
  box-shadow: 0px -2px 53px rgba(20, 48, 189, 0.03),
    0px -0.835552px 22.1421px rgba(20, 48, 189, 0.0215656),
    0px -0.446726px 11.8382px rgba(20, 48, 189, 0.0178832),
    0px -0.250431px 6.63642px rgba(20, 48, 189, 0.015),
    0px -0.133002px 3.52455px rgba(20, 48, 189, 0.0121168),
    0px -0.0553451px 1.46665px rgba(20, 48, 189, 0.00843437);
  border-radius: 18px;
  padding: 45px;
  z-index: 1;

  & > strong {
    display: block;
    color: ${(props) => props.theme.primary};
    font-size: 18px;
  }

  & > p {
    font-size: 14px;
    color: ${(props) => props.theme.secondary};
    margin: 0;
  }

  & > p {
    font-size: 14px;
    color: ${(props) => props.theme.secondary};
    margin: 0;
    margin-top: 30px;
  }

  & > small {
    display: block;
    color: ${(props) => props.theme.dark};
    opacity: 0.4;
    font-size: 14px;
  }

  @media ${device.tablet} {
    margin-top: 16px;
    width: 100%;
  }
`;

const SvgWrapper = styled.div`
  text-align: center;
`;

const ProductButton = styled(ButtonLink)`
  margin-top: 30px;
`;

const StyledSmallLineDots = styled(SmallLineDots)`
  position: absolute;
  right: 20px;
  bottom: 0;
`;

const StyledSmallLineDots2 = styled(SmallLineDots)`
  position: absolute;
  left: 20px;
  bottom: 0;
  z-index: 0;
`;

function ProductsPage() {
  const { learnMore, getStarted } = useCommonTitles();
  const {
    heroSectionTitle,
    heroSectionDescription,
    title,
    subtitle,
  } = useStaticQuery(graphql`
    {
      productsYaml {
        heroSectionTitle
        heroSectionDescription
        title
        subtitle
      }
    }
  `).productsYaml;
  return (
    <>
      <Seo title="Products" />
      <PageHeading title={heroSectionTitle} subtitle="Products">
        <div>
          {heroSectionDescription.split('\n').map((line) => (
            <Paragraph $inverted>{line}</Paragraph>
          ))}
          <ActionButtonsContainer>
            <ButtonLink color={ButtonColor.Secondary} href="/console/signup">
              {getStarted}
            </ButtonLink>
            <ButtonLink
              color={ButtonColor.PrimaryWithBorder}
              href="/console/signin"
            >
              {learnMore}
            </ButtonLink>
          </ActionButtonsContainer>
        </div>
      </PageHeading>
      <PageContentWrapper>
        <StyledSmallLineDots />
        <StyledSmallLineDots2 />
        <MarginContainer>
          <Heading2>
            <MultilineText text={title} />
          </Heading2>
          <Paragraph>{subtitle}</Paragraph>
          <ProductsList>
            <Product>
              <SvgWrapper>
                <MySql />
              </SvgWrapper>
              <p>Shared Service</p>

              <strong>MySql 5.7</strong>

              <p>Shared and Dedicated Service</p>
              <strong>MySql 8.0</strong>
              <p>Only for high availability</p>
              <ProductButton color={ButtonColor.Primary} to="/products/mysql">
                {learnMore}
              </ProductButton>
            </Product>
            <Product>
              <SvgWrapper>
                <MariaDb />
              </SvgWrapper>
              <p>Shared Service</p>

              <strong>MariaDB 10.5</strong>
              <ProductButton color={ButtonColor.Primary} to="/products/mariadb">
                {learnMore}
              </ProductButton>
            </Product>
            <Product>
              <SvgWrapper>
                <PostgreSql />
              </SvgWrapper>
              <p>Dedicated Service</p>

              <strong>Postgres 11.9</strong>
              <strong>Postgres 12.4</strong>
              <strong>Postgres 13.0 (beta)</strong>

              <small>All only for high availability</small>
              <ProductButton
                color={ButtonColor.Primary}
                to="/products/postgresql"
              >
                {learnMore}
              </ProductButton>
            </Product>
          </ProductsList>
        </MarginContainer>
      </PageContentWrapper>
      <JoinTheCloudSection />
    </>
  );
}

export default ProductsPage;
